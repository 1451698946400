import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from '../config';
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import VideoCarousel from "./Videoslider";
import gifLoad from "../Images/video/Rload.gif";

function Home() {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {

        await new Promise((resolve) => setTimeout(resolve, 1500));
        const response = await axios.get(
          `${BASE_URL}/page-content/category/2`
        );
        console.log(response.data);
        if (response.data) {
          setContent(response.data);
         
        }
      } catch (error) {
        console.error(error);
        
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <img src={gifLoad} alt="Loading..." />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#FFF6E9" }}>
      <Header />
      <VideoCarousel />

      <div className="HomePage">
        <div className="container-fluid">
          {content.length === 0 ? (
            <div>No content available.</div>
          ) : (
            content.map((item, index) => (
              <div key={item.id} className="container">
                <div
                  className="row my-5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {index % 2 === 0 ? (
                    <>
                      <div className="col-md-6">
                        <div className="homepage-imageBox">
                          <img
                            src={`https://rasoiindiancuisine.friendzbazzar.com/${item.image}`}
                            alt={item.title}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-center p-2">
                          <div className="pb-4 pt-3">
                            <h6 className="mx-auto">
                             
                              <Link className="oval" to="/Menu">
                                {item.title}
                              </Link>
                            </h6>
                          </div>
                          <p className="para">{item.description}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <div className="text-center p-2">
                          <div className="pb-4 pt-3">
                            <h6 className="mx-auto">
                              <Link className="oval" to="/Menu">
                                {item.title}
                              </Link>
                            </h6>
                          </div>
                          <p className="para">{item.description}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="homepage-imageBox">
                          <img
                            src={`https://rasoiindiancuisine.friendzbazzar.com/${item.image}`}
                            alt={item.title}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;

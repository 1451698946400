import React from 'react';
import logo from '../Images/logo.png';
import logo2 from '../Images/logo2.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-md">
      <div className="container-fluid">

        <Link className="navbar-brand logo" to="/">
          <img src={logo2} alt="Logo" />
        </Link>


        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end gap-3 custom-collapse my-5" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/About">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Menu">Menu</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="#">order online</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Contact">Contact</Link>
            </li>
            
            {/* <li className="nav-item">
              <Link className="nav-link" to="https://rassoi.friendzbazzar.com/">Login</Link>
            </li> */}

          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

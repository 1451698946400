import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import vid5 from "../Images/video/vid5.mp4";
import vnload from "../Images/video/vnload.gif";

function VideoCarousel() {
  const [videos, setVideos] = useState([]);
  const videoRefs = useRef([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/videos`)
      .then((response) => {
        if (response.data && response.data.videos.length > 0) {
          setVideos(response.data.videos);
          setLoadingStates(new Array(response.data.videos.length).fill(true));
          console.log(response.data.videos);
        } else {
          console.log("No videos available");
        }
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, []);

  useEffect(() => {
    const handleSlide = (e) => {
      videoRefs.current.forEach((videoRef) => {
        if (videoRef) {
          videoRef.pause();
        }
      });
      const activeIndex = e.to;
      if (videoRefs.current[activeIndex]) {
        videoRefs.current[activeIndex]
          .play()
          .catch((error) => console.error("Error attempting to play:", error));
        setPlayingIndex(activeIndex);
      }
    };

    const carousel = document.getElementById("videoCarousel");
    carousel.addEventListener("slid.bs.carousel", handleSlide);

    return () => {
      carousel.removeEventListener("slid.bs.carousel", handleSlide);
    };
  }, [videos]);

  const togglePlayPause = (index) => {
    const videoRef = videoRefs.current[index];
    if (videoRef.paused) {
      videoRef
        .play()
        .catch((error) => console.error("Error attempting to play:", error));
      setPlayingIndex(index);
    } else {
      videoRef.pause();
      setPlayingIndex(null);
    }
  };

  const getVideoUrl = (filepath) => {
    return `https://rasoiindiancuisine.friendzbazzar.com/${filepath.replace(
      /\\/g,
      "/"
    )}`;
  };

  const handleVideoError = (index) => {
    videoRefs.current[index].src = vid5;
    videoRefs.current[index]
      .play()
      .catch((error) =>
        console.error("Error attempting to play fallback video:", error)
      );
  };

  const handleVideoLoadedData = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div>
      <div
        id="videoCarousel"
        className="carousel slide mt-5 pt-5"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {videos.length > 0 ? (
            videos.map((video, index) => (
              <div
                key={video.id}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div className="ratio ratio-16x9">
                  {loadingStates[index] && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        src={vnload}
                        height={25}
                        width={25}
                        alt="Loading..."
                      />
                    </div>
                  )}
                  <div className="video-container">
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      className="d-block w-100 h-100"
                      loop
                      muted
                      autoPlay
                      onClick={() => togglePlayPause(index)}
                      controlsList="nodownload"
                      disablePictureInPicture
                      onError={() => handleVideoError(index)}
                      onLoadedData={() => handleVideoLoadedData(index)}
                      preload="auto"
                    >
                      <source
                        src={getVideoUrl(video.filepath)}
                        type={video.mimetype}
                      />
                    </video>
                    <div
                      className="play-button"
                      onClick={() => togglePlayPause(index)}
                    >
                      {playingIndex === index ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polygon points="5 3 19 12 5 21 5 3" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <rect x="9" y="5" width="2" height="14" />
                          <rect x="17" y="5" width="2" height="14" />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="carousel-item active">
              <div className="ratio ratio-16x9">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                  }}
                >
                  <img src={vnload} height={25} width={25} alt="Loading..." />
                </div>
              </div>
            </div>
          )}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#videoCarousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#videoCarousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default VideoCarousel;
